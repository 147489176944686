//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { registerGeneticAnalyst, createQualification } from "@debionetwork/polkadot-provider";
import { u8aToHex } from "@polkadot/util";
import CryptoJS from "crypto-js";
import Kilt from "@kiltprotocol/sdk-js";
import GAForm from "@/common/components/Account/InformationForm";
const stepData = [{
  label: "Set Up Account",
  active: true
}, {
  label: "Set Up Service",
  active: false
}];
export default {
  name: "GARegistration",
  components: {
    GAForm
  },
  data: () => ({
    stepData: stepData,
    publicKey: null,
    loading: false
  }),
  computed: {
    ...mapState({
      mnemonicData: state => state.substrate.mnemonicData,
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  mounted() {
    if (this.mnemonicData) this.initialDataKey();
  },
  methods: {
    initialDataKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      const publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.publicKey = publicKey;
      return publicKey;
    },
    async onSubmitInformation(value) {
      this.loading = true;
      const {
        certification,
        dateOfBirth,
        email,
        experiences,
        firstName,
        gender,
        lastName,
        phoneNumber,
        profileImage,
        specialization,
        specifyOther,
        profileLink
      } = value;
      const _experiences = experiences.filter(value => value != "");
      const _specialization = specialization == "Other" ? specifyOther : specialization;
      const _dateOfBirth = new Date(dateOfBirth);
      const qualification = {
        experience: _experiences,
        certification: certification
      };
      try {
        await registerGeneticAnalyst(this.api, this.wallet, {
          boxPublicKey: this.initialDataKey(),
          firstName,
          lastName,
          gender,
          dateOfBirth: _dateOfBirth.getTime(),
          email,
          phoneNumber,
          specialization: _specialization,
          profileImage,
          profileLink
        });
        await createQualification(this.api, this.wallet, qualification);
        this.$router.push("registration/service");
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  }
};